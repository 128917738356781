import React, { useEffect } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

const OverlayGallery = ({ images, open, slideIndex, close }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true })

  const scrollPrev = (event) => {
    event.stopPropagation()
    emblaApi && emblaApi.scrollPrev()
  }

  const scrollNext = (event) => {
    event.stopPropagation()
    emblaApi && emblaApi.scrollNext()
  }

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0]

    if (open) {
      body.classList.add("no-scroll")

      setTimeout(() => {
        emblaApi && emblaApi.scrollTo(slideIndex)
      }, 50)
    } else {
      body.classList.remove("no-scroll")
    }
  }, [open])

  return (
    <div
      onClick={close}
      className={`overlay-container ${open ? "overlay-container--visible" : ""}`}
    >
      <div
        className="embla-overlay__close-wrapper"
        onClick={close}
      >
        <CloseRoundedIcon className="embla-overlay__close-icon" fontSize="large" />
      </div>
      <div className="embla-overlay" ref={emblaRef}>
        <div className="embla-overlay__container">
          {images.map(image => (
            <div className={`embla-overlay__slide`}>
              <div className="embla-overlay__slide-wrapper">
                <img src={image?.img ?? image} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        onClick={scrollPrev}
        className="embla-overlay__button-wrapper embla-overlay__button-wrapper-left"
      >
        <button className="embla-overlay__button" onClick={scrollPrev}>
          <ArrowBackIosNewRoundedIcon />
        </button>
      </div>
      <div
        onClick={scrollNext}
        className="embla-overlay__button-wrapper embla-overlay__button-wrapper-right"
      >
        <button className="embla-overlay__button" >
          <ArrowForwardIosRoundedIcon />
        </button>
      </div>
    </div>
  )
}

export default OverlayGallery;
