import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import RestaurantIcon from '@mui/icons-material/Restaurant';

const icons = {
  mail: EmailIcon,
  person: PersonIcon,
  food: RestaurantIcon
}

const InputWithIcon = ({
  label,
  id,
  value,
  onChange,
  onBlur,
  error,
  icon,
  helperText,
  type = "text",
  useIcon = true
}) => {
  const Icon = icons[icon]

  return (
    <div className="text-field-wrapper">
      <TextField
        type={type}
        fullWidth
        label={label}
        id={id}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        helperText={helperText}
        InputProps={{
          startAdornment: useIcon ? (
            <InputAdornment position="start">
              <Icon className="icon" fontSize="small" />
              <div className="divider" />
            </InputAdornment>
          ) : null
        }}
      />
    </div>
  )
}

export default InputWithIcon;
