import { useState } from 'react';

import './styles/style.sass';
import HelloThere from './components/HelloThere';
import Header from './components/header';
import Hero from './components/Hero';
import HeroFooter from './components/HeroFooter';
import Schedule from './components/Schedule';
import OurLife from './components/OurLife';
import Location from './components/Location';
import ContactUs from './components/ContactUs';
import RsvpManager from './components/RsvpManager';

const App = () => {
  const [passwordValid, setPasswordValid] = useState(false);

  const handleSetPassValid = () => {
    setPasswordValid(true)
  }

  return (
    <div className={!passwordValid ? "page-wrapper--locked" : ""}>
      <HelloThere setPassAsValid={handleSetPassValid} keepOpen={!passwordValid} />
      <Header />
      <Hero />
      <HeroFooter />
      <Schedule />
      <OurLife />
      <Location />
      <RsvpManager passwordValid={passwordValid} />
      <ContactUs />
    </div>
  )
}

export default App;
