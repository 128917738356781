import { useEffect, useState } from 'react';
import Section from './Section';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import NavMobileMenu from './NavMobileMenu';

const logoPath = '../assets/logo.png';

const navLinks = [
  {
    label: "Welcome",
    className: "",
    hash: "#welcome"
  },
  {
    label: "Schedule",
    className: "",
    hash: "#schedule"
  },
  {
    label: "Our Life",
    className: "",
    hash: "#ourLife"
  },
  {
    label: "Location",
    className: "",
    hash: "#location"
  },
  {
    label: "Logistics",
    className: "header__nav-link--hidden",
    hash: "#logistics"
  },
  {
    label: "RSVP",
    className: "",
    hash: "#rsvp"
  },
  {
    label: "Contact",
    className: "header__nav-link--last",
    hash: "#contact"
  },
]

const NavLink = ({
  className = "",
  hash,
  label
}) => {
  const onClick = () => {
    const scrollTarget = document.getElementById(hash);

    scrollTarget.scrollIntoView({ behavior: "smooth" })
  }

  return (<li className={`header__nav-link ${className}`} onClick={onClick}>{label}</li>);
};

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [headerScrolled, setHeaderScrolled] = useState(false);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      if (window.scrollY > 70) {
        setHeaderScrolled(true)
      } else if (!headerScrolled && window.scrollY < 70) {
        setHeaderScrolled(false)
      }
    })
  }, [])

  const handleOpenMenu = () => {
    setMenuOpen(true)
  }

  const handleCloseMenu = () => {
    setMenuOpen(false)
  }

  return (
    <div className={`header ${headerScrolled ? "header--grey" : ""}`}>
      <Section>
        <div className={`header__inner-wrapper ${headerScrolled ? "header__inner-wrapper--crunched" : ""}`}>
          <img src={logoPath} className="header__logo" />
          <div className="header__nav-burger">
            <MenuRoundedIcon
              fontSize="large"
              className="header__burger-icon"
              onClick={handleOpenMenu}
            />
          </div>
          <nav className="header__nav-wrapper">
            <ul className="header__link-list">
              {navLinks.map(linkProps => (
                <NavLink {...linkProps} />
              ))}
            </ul>
          </nav>
        </div>
      </Section>
      <div className={`mobile-nav-wrapper ${menuOpen ? "mobile-nav-wrapper--open" : ""}`}>
        <NavMobileMenu
          menuOpen={menuOpen}
          navLinks={navLinks}
          closeMenu={handleCloseMenu}
        />
      </div>
    </div>
  )
}

export default Header;