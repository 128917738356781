import Dialog from '@mui/material/Dialog';
import { SecondaryButton } from './Buttons';

const GiftsDialog = ({ open, onClose }) => (
  <Dialog open={open} onClose={onClose}>
    <div className="gifts">
      <div className="gifts__title">
        Gift Idea
      </div>
      <div className="gifts__subtitle">
        We recognize the effort incurred by you in coming to celebrate with us all the way in Italy and having you here is a gift in itself. However, if you feel compelled to give us a wedding gift, we would be grateful for a cash contribution to our honeymoon (to be collected during your stay here). Immediately after the wedding weekend we are planning a 10-day trip to Amalfi coast, to enjoy the final days of summer.
      </div>
      <SecondaryButton
        fullWidth
        text="Close"
        onClick={onClose}
      />
    </div>
  </Dialog>
)

export default GiftsDialog;