import React, { useState } from "react";
import useEmblaCarousel from 'embla-carousel-react';

import Section from './Section';
import SectionHeader from './SectionHeader';
import OverlaySlider from './OverlayGallery';

import img1 from '../assets/couple/pic_1.jpg';
import img2 from '../assets/couple/pic_2.jpg';
import img3 from '../assets/couple/pic_3.jpg';
import img4 from '../assets/couple/pic_4.jpg';
import img5 from '../assets/couple/pic_5.jpg';
import img6 from '../assets/couple/pic_6.jpg';

const subTitleString = "We met in January 2016 in Warsaw - at the time we were both living and studying in England. Over the last 7 years we have been together throughout our studies, first jobs, apartments and travels to each corner the world . We now both live in Warsaw, close to our families, with our beautiful cat. However, for this joyous occasion we have decided to invite you all to Italy -  a country we both love -  for the views, the food and the people. We hope you can join us!"

const coupleImages = [
  img1,
  { img: img4, className: "img4" },
  img2,
  { img: img5, className: "img5" },
  img3,
  { img: img6, className: "img6" }
]

const OurLife = () => {
  const [open, setOpen] = useState(false)
  const [slideIndex, setSlideIndex] = useState(0)
  const [emblaRef] = useEmblaCarousel({ dragFree: true })

  const setIndex = (index) => {
    setSlideIndex(index)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <Section wrapperClassNames="wrapper-white" classNames="section-full" id="#ourLife">
      <div className="our-life">
        <SectionHeader title="Our story" subtitle={subTitleString} selfContained />
        <div className="embla" ref={emblaRef}>
          <div className="embla__container">
            {coupleImages.map((image, index) => (
              <div
                className={`embla__slide ${image?.className ?? ""}`}
                onClick={(event) => {
                  event.stopPropagation()
                  setIndex(index)
                  handleOpen()
                }}
              >
                <img src={image?.img ?? image} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <OverlaySlider open={open} images={coupleImages} slideIndex={slideIndex} close={handleClose} />
    </Section>
  )
}

export default OurLife;
