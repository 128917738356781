import InputWithIcon from './InputWithIcon';

import CloseIcon from '@mui/icons-material/Close';

const PartyMember = ({ values, handleChange, handleBlur, index, fieldError, errorMsg, handleRemoveMember }) => {
  const { name, surname, dietary } = values

  const typedInNames = name !== "" && surname !== "";
  const header = typedInNames ? `${index + 1}. ${name} ${surname}` : `${index + 1}. Party Member`;

  return (
    <div className="party-member">
      <div className="party-member__header">
        <div>{header}</div>
        <div className="party-member__icon" onClick={handleRemoveMember}>
          <CloseIcon />
        </div>
      </div>
      <InputWithIcon
        id={`partyMembers.${index}.name`}
        label="First Name"
        value={name}
        onChange={handleChange}
        onBlur={handleBlur}
        error={fieldError("name", index)}
        helperText={errorMsg("name", index)}
        icon="person"
      />
      <InputWithIcon
        id={`partyMembers.${index}.surname`}
        label="Last Name"
        value={surname}
        onChange={handleChange}
        onBlur={handleBlur}
        error={fieldError("surname", index)}
        helperText={errorMsg("surname", index)}
        icon="person"
      />
      <InputWithIcon
        id={`partyMembers.${index}.dietary`}
        label="Dietary requirements (allergies, preferences, vegetarian, vegan)"
        value={dietary}
        onChange={handleChange}
        onBlur={handleBlur}
        error={fieldError("dietary", index)}
        helperText={errorMsg("dietary", index)}
        icon="food"
      />
    </div>
  )
}

export default PartyMember;