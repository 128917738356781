import Section from './Section';

const heroPath = '../assets/hero.jpg';

const Hero = () => (
  <Section classNames="section-full" id="#welcome">
    <div className="hero">
      <img src={heroPath} className="hero-image" />
    </div>
  </Section>
)

export default Hero;
