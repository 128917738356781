import { useState, useEffect, useRef } from 'react';

import happyCouple from '../assets/couple/pic_1.jpg';
import bigLogo from '../assets/logo-lg.png';
import Dialog from '@mui/material/Dialog';

const passwordVal = "IWantToCelebrate!";

const HelloThere = ({ keepOpen, setPassAsValid }) => {
  const inputRef = useRef(null);
  const [password, setPassword] = useState('');

  useEffect(() => {
    // scroll stuff of since keyboard pushes scroll a little bit down
    if (password === passwordVal) {
      setPassAsValid()
    }
  }, [password])

  return (
    <Dialog open={keepOpen} fullScreen transitionDuration={0}>
      <div className="hello-there">
        {/* <img src={happyCouple} className="hello-there__image" /> */}
        <div className="hello-there__content-wrapper">
          <img src={bigLogo} className="hello-there__logo" />
          <div className="hello-there__content">
            <input
              ref={inputRef}
              type="text"
              className="hello-there__pass-input"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                const newValue = e.target.value;
                setPassword(newValue)
              }}
            />
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default HelloThere;
