import Button from '@mui/material/Button';

const PrimaryButton = ({ text, ...otherProps }) => (
  <div className="primary-button-wrapper">
    <Button
      variant="outlined"
      size="large"
      {...otherProps}
    >
      {text}
    </Button>
  </div>
)

const SecondaryButton = ({ text, ...otherProps }) => (
  <div className="secondary-button-wrapper">
    <Button
      variant="contained"
      size="large"
      {...otherProps}
    >
      {text}
    </Button>
  </div>
)

export { PrimaryButton, SecondaryButton }