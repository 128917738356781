import { useState } from "react"

import Section from './Section';
import SectionHeader from './SectionHeader';
import BeThereForm from "./BeThereForm";

const RsvpManager = ({ passwordValid }) => {
  const [requestState, setRequestState] = useState(null)
  const [showSummary, setShowSummary] = useState(false)
  const [rsvpData, setRsvpData] = useState(null);

  const handleShowSummary = (data) => {
    setRsvpData(data)
    setShowSummary(true)
  }

  const showForm = !showSummary

  const indicatorMap = {
    loading: "Sending your RSVP...",
    sent: "Your RSVP was delivered successfully",
    error: "Something went wrong! Try again or contact us directly"
  }

  const indicatorClass = {
    loading: "sending",
    sent: "success",
    error: "failure"
  }

  const hasData = Boolean(rsvpData)

  const hasPartyMembers = hasData && rsvpData.partyMembers && rsvpData.partyMembers.length !== 0

  return (
    <Section id="#rsvp">
      <div className="manager">
        <SectionHeader
          title="what are you waiting for?"
          subtitle="We would greatly appreciate if you could RSVP 05.05.2024."
        />
        {showForm && (
          <BeThereForm
            passwordValid={passwordValid}
            formSendSuccess={handleShowSummary}
            requestState={requestState}
            setRequestState={setRequestState}
          />
        )}
        {showSummary && hasData && (
          <div className="manager__paper">
            <div className="manager__title">Your RSVP summed up!</div>
            <div className="manager__you">
              <div className="manager__person">You</div>
              <div className="manager__name manager__row">{rsvpData.name} {rsvpData.surname}</div>
              <div className="manager__email manager__row">{rsvpData.email}</div>
              <div className="manager_dietary manager__row">{rsvpData.dietary}</div>
            </div>
            {hasPartyMembers && (
              <div className="manager__members">
                <div className="manager__person">Your party members</div>
                {rsvpData.partyMembers.map(member => (
                  <div className="manager__member">
                    <div className="manager__name manager__row">{member.name} {member.surname}</div>
                    <div className="manager_dietary manager__row">{member.dietary}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        {requestState && (
          <div className={`manager__indicator manager__indicator--${indicatorClass[requestState]}`}>
            {indicatorMap[requestState]}
          </div>
        )}
      </div>
    </Section>
  )
}

export default RsvpManager;