import Section from './Section';

const HeroFooter = () => (
  <Section wrapperClassNames="wrapper-white">
    <div className="hero-footer">
      <div className="getting-married">
        We are getting married!
    </div>
      <p className="date-married">
        We are taking the next step in our adventure together, and we can't wait for You to be part of our celebration.
      </p>
    </div>
  </Section>
)

export default HeroFooter;
