import bigLogo from '../assets/logo-lg.png';

const NavMobileMenu = ({ menuOpen, navLinks, closeMenu }) => {
  const handleNav = (hash) => {
    setTimeout(() => {
      const scrollTarget = document.getElementById(hash);

      scrollTarget.scrollIntoView({ behavior: "smooth" })
    }, 50)

    closeMenu();
  }
  return (
    <div className={`mobile-menu ${menuOpen ? "mobile-menu--open" : ""}`}>
      <img src={bigLogo} className="mobile-menu__logo" />
      <div>
        <ul className="mobile-menu__nav">
          {navLinks.map(({ label, hash }) => (
            <li className="mobile-menu__nav-link" onClick={() => handleNav(hash)}>{label}</li>
          ))}
        </ul>
        <div onClick={closeMenu} className="mobile-menu__close-button">Close Menu</div>
      </div>
    </div>
  )
}

export default NavMobileMenu;