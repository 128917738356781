import { useEffect, useState } from 'react'
import useEmblaCarousel from 'embla-carousel-react';

import Section from "./Section"
import SectionHeader from './SectionHeader'

import img1 from '../assets/location/castle_1.jpeg'
import img2 from '../assets/location/castle_2.jpeg'
import img3 from '../assets/location/castle_3.jpeg'
import img4 from '../assets/location/castle_4.jpeg'
import img5 from '../assets/location/castle_5.jpeg'

const castleImages = [
  img1,
  img2,
  img3,
  img4,
  img5
]

const airportStrings = [
  "Florence - ca. 1hr drive",
  "Pisa - ca. 1.5hrs drive",
  "Rome - ca. 2.5hrs drive"
]

const SubHeader = () => (
  <div>
    <div>
      We are happy to tell you, that for the course of the celebrations, Castel Monastero is booked solely for us. This means you don't have to worry about booking a place to sleep - we all sleep where we party, no commute time! We have booked and covered a room for you for the nights of 23rd, 24th and 25th August 2024. We know that all of you will be travelling from different parts of the world - we are happy to help in the organization of transport, just let us know! Due to the remote location of the venue, we recommend driving from the airport.
    </div>
    <div className="location__airports-list">
      <div className="location__airports-title">
        Closest airports
      </div>
      {airportStrings.map(airport => (
        <div className="location__airport">{airport}</div>
      ))}
    </div>
    <div>
      Check out more about
      {" "}
      <a className="location__castle-link" href="https://www.castelmonastero.com/" target="_blank">Castle Monastero</a>
      {" "}
      yourself!
    </div>
  </div>
)

const Location = () => {
  const [emblaRef] = useEmblaCarousel({ dragFree: true, direction: "rtl" })

  return (
    <Section wrapperClassNames="wrapper-grey" classNames="section-full" id="#location">
      <div className="location">
        <SectionHeader
          title="Location and Logistics"
          subtitle={<SubHeader />}
          selfContained
        />
        <div className="embla" ref={emblaRef}>
          <div className="embla__container">
            {castleImages.map((image) => (
              <div
                className={`embla__slide ${image?.className ?? ""}`}
              >
                <img src={image?.img ?? image} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Location;
