import { object, string, email, array } from 'yup';
import { useFormik } from 'formik'

import InputWithIcon from './InputWithIcon'
import PartyMember from './PartyMember'
import { PrimaryButton, SecondaryButton } from './Buttons'
import { uploadGuestDataToGoogleSheet } from '../utils/uploadFormData';

const guestsSchema = object().shape({
  email: string().email('Please provide valid email').required('Field Required'),
  name: string().required('Field Required'),
  surname: string().required('Field Required'),
  dietary: string(),
  partyMembers: array().of(
    object().shape({
      name: string().required('Field Required'),
      surname: string().required('Field Required'),
      dietary: string()
    })
  )
})

const emptyPartyMember = {
  name: "",
  surname: "",
  dietary: "",
}

const BeThereForm = ({ passwordValid, formSendSuccess, setRequestState }) => {

  const handleFormDataSend = async (values) => {
    setRequestState("loading");

    try {
      await uploadGuestDataToGoogleSheet(values)

      setRequestState("sent");

      setTimeout(() => {
        formSendSuccess(values)
      }, 150)
    } catch (e) {
      setRequestState("error")
    }
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      surname: "",
      dietary: "",
      partyMembers: []
    },
    validationSchema: guestsSchema,
    onSubmit: (values) => {
      passwordValid && handleFormDataSend(values)
    }
  })

  const handleAddPartyMember = () => {
    formik.setValues({
      ...formik.values,
      partyMembers: [...formik.values.partyMembers, { ...emptyPartyMember }]
    })
  }

  const handleRemovePartyMember = (index) => {
    const modifiedArray = [...formik.values.partyMembers];

    modifiedArray.splice(index, 1);

    formik.setValues({
      ...formik.values,
      partyMembers: [...modifiedArray]
    })
  }

  const getMemberErrorStackFor = () => (field, index) => formik.touched.partyMembers?.[index]?.[field] && Boolean(formik.errors.partyMembers?.[index]?.[field])

  const getErrorMsgStackFor = () => (field, index) => formik.touched.partyMembers?.[index]?.[field] && formik.errors.partyMembers?.[index]?.[field]

  return (
    <form className="form" onSubmit={formik.handleSubmit}>
      <InputWithIcon
        label="First Name"
        id="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        icon="person"
      />
      <InputWithIcon
        label="Last Name"
        id="surname"
        value={formik.values.lastName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.surname && Boolean(formik.errors.surname)}
        helperText={formik.touched.surname && formik.errors.surname}
        icon="person"
      />
      <InputWithIcon
        label="Your mail"
        id="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        icon="mail"
      />
      <InputWithIcon
        label="Dietary requirements (allergies, preferences, vegetarian, vegan)"
        id="dietary"
        value={formik.values.dietary}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.dietary && Boolean(formik.errors.dietary)}
        helperText={formik.touched.dietary && formik.errors.dietary}
        icon="food"
      />
      <div className="form__more-people">
        <div className="form__add-people">Please add other guests from your party using <span className="form__people-bold">"Add another member"</span> button so we can accomodate for them and their dietary needs!</div>
        <div>If you are invited with a significant other but are not sure whom you will be taking at the moment of RSVP, please add a party member and fill all the fields with "TBC" and get back to us individually as soon as you can. This will allow us to confirm the number of guests at an earlier time and plan accordingly.</div>
      </div>

      {formik.values.partyMembers.map((partyMember, index) => (
        <PartyMember
          index={index}
          values={partyMember}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          handleRemoveMember={() => handleRemovePartyMember(index)}
          fieldError={getMemberErrorStackFor()}
          errorMsg={getErrorMsgStackFor()}
        />
      ))}

      <div className="form__add-more">
        <PrimaryButton
          text="Add another member"
          onClick={handleAddPartyMember}
          fullWidth
        />
      </div>
      <div className="form__submit">
        <SecondaryButton
          fullWidth
          text="Confirm"
          type="submit"
          disabled={!passwordValid}
        />
      </div>
    </form>
  )
}

export default BeThereForm;