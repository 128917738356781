
// TODO: replace with link from Paweł config
// Goodle Sheets integration guide https://github.com/levinunnink/html-form-to-google-sheet
const spreadSheetLink = "https://script.google.com/macros/s/AKfycbz7zWFMGZ3OQv5_vKNKD0iAhBOsHA4CoZ9IkHP6Pm7-PEM-tMi8NY8GYEhk6IEdQVo/exec"

const createFormDataAndAddDate = (obj) => {
  const objAsFormData = new FormData();

  Object.entries({ ...obj, date: new Date() }).forEach(([key, value]) => {
    objAsFormData.append(key, value);
  });

  return objAsFormData;
}

const generateFormDataFromGuestData = ({ partyMembers, ...mainGuest }) => {
  const formattedMembersData = [];

  formattedMembersData.push(createFormDataAndAddDate(mainGuest))

  partyMembers.forEach(partyMember => {
    formattedMembersData.push(createFormDataAndAddDate(partyMember))
  })

  return formattedMembersData
}

// WARN: "fetch" will not return error if body data does not match scheet columns
// it considers request as resolved if it was able to trigger "doPost" script and thats it
export const uploadGuestDataToGoogleSheet = async (formikValues) => {
  const guestFormData = generateFormDataFromGuestData(formikValues);

  try {
    console.log("---> sending form data")
    await Promise.all(guestFormData.map(async (guest) => {
      return await fetch(spreadSheetLink, {
        method: 'POST',
        body: guest,
      })
    }))

    console.log("--> form data sent")
    return Promise.resolve("done")
  } catch (e) {
    console.log("--> failed to send form data", e)
    return Promise.reject(e)
  }
}