import Section from "./Section";
import SectionHeader from "./SectionHeader";

const ContactUs = () => (
  <Section id="#contact">
    <div className="contact-us">
      <SectionHeader title="Contact Us" />
      <div className="contact-us__contact-info">
        <div className="contact-us__in-case">In case of any questions or queries please contact us by phone, we would love to clear anything up! Please find our phone numbers below.</div>
        <div>Paweł: <span className="contact-us__phone">+48 600 085 876</span></div>
        <div>Klara: <span className="contact-us__phone">+48 606 131 818</span></div>
      </div>
      <div className="contact-us__separator-parent">
        <div className="contact-us__circle" />
        <div className="contact-us__line" />
      </div>
      <div className="contact-us__crafted">
        Crafted by Pawel with lots of &#10084;&#65039; for Klara
      </div>
    </div>
  </Section>
)

export default ContactUs;
