import Dialog from '@mui/material/Dialog';
import { SecondaryButton } from './Buttons';
import palette from '../assets/dresscode.png'

const events = [
  {
    event: "Welcome dinner",
    code: "Smart - casual",
    colors: "Colors: Red and White - colors of both Poland and Italy",
    description: ""
  },
  {
    event: "Wedding day",
    code: "Cocktail wedding attire / black-tie",
    colors: "Please see attached color palette for recommended colors",
    img: palette,
    description: "It is August in Italy so we don't want anybody to boil in a black suit. Having said that , if that is what you feel best in or you don't mind the temepratures - feel free!"
  },
  {
    event: "Rest day",
    code: "Swim-suits, tracksuits, just suits",
    description: "Wear whatever you are comfortable with!"
  }
]

const DressCodeDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <div className="dress-code">
        <div className="dress-code__title">
          The dress code
        </div>
        <div className="dress-code__subtitle">
          So you don't have to waste too much time on what to pack
        </div>
        <div className="dress-code__events">
          {events.map(({ event, code, colors, description, img }) => (
            <div className="event">
              <div className="event__title">{event}</div>
              <div className="event__code">{code}</div>
              <div className="event__colors">{colors}</div>
              {img && <img className="event__img" src={img} />}
              <div className="event__desc">{description}</div>
            </div>
          ))}
        </div>
        <SecondaryButton
          fullWidth
          text="Close"
          onClick={onClose}
        />
      </div>
    </Dialog>
  )
}

export default DressCodeDialog;
