const SectionHeader = ({ title, subtitle, selfContained }) => (
  <>
    <div className={`section-header ${Boolean(subtitle) ? "section-header--with-sub" : ""} ${selfContained ? "section-header--contained" : ""}`}>
      {title}
    </div>
    {subtitle && (
      <div className={`section-subheader ${selfContained ? "section-subheader--contained" : ""}`}>
        {subtitle}
      </div>
    )}
  </>
)

export default SectionHeader;