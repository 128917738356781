import { useState } from 'react'
import Section from './Section';
import { SecondaryButton, PrimaryButton } from './Buttons'
import DressCodeDialog from './DressCodeDialog';
import GiftsDialog from './GiftsDialog';

// WARN: list order sets visual order
const daysList = [
  {
    day: "23th August",
    label: "Friday",
    events: [
      {
        title: "Arrival",
        time: "from 15.00",
        description: "Your room will be ready for you to enjoy and wind down after the travel."
      },
      {
        title: "Dinner",
        time: "from 19:00",
        description: "We are glad you are here - here is a first chance to meet everyone and enjoy some good italian food."
      }
    ]
  },
  {
    day: "24th August",
    label: "Wedding day!",
    events: [
      {
        title: "Breakfast",
        time: "7.30 – 10.30",
      },
      {
        title: "Light lunch",
        time: "12.00 – 13.30",
        description: "Sandwiches and salads available in a buffet form."
      },
      {
        title: "Pre-ceremony drinks",
        time: "from 15.30",
        description: "Have a glass of prosecco and enjoy the afternoon sun."
      },
      {
        title: "Ceremony",
        time: "17.00"
      },
      {
        title: "First round of celebratory drinks",
        time: "from 17.30",
        description: "It’s official – start the celebration with some drinks and snacks."
      },
      {
        title: "Dinner and dancing",
        time: "from 19.00",
        description: "Time for some good food, speeches and dancing."
      }
    ]
  },
  {
    day: "25th August",
    label: "Sunday",
    events: [
      {
        title: "Late breakfast",
        time: "9.00 - 13.00",
        description: "Get up, stretch your legs, don't waste a beautiful day!",
      },
      {
        title: "Rest-day",
        time: "after 13.00",
        description: "Enjoy your day by the pool while sipping prosecco, make a trip to Siena or take a bike out into the Tuscan hills! More details soon."
      },
      {
        title: "dinner",
        time: "from 19.00",
        description: "Refuel for the coming week, eat some good pasta and enjoy the final evening with all of the new friends you made."
      }
    ]
  },
  {
    day: "26th August",
    label: "Monday",
    events: [
      {
        title: "Breakfast",
        time: "7.30 – 10.30",
      },
      {
        title: "Goodbye",
        time: "Chek-out until 11.00",
        description: "We hope you will take lots of happy memories with you and safe travels!",
      }
    ]
  },
]

const Day = ({ day, label, events, first, second }) => (
  <div className={`schedule-day ${first ? "schedule-day--first" : ""} ${second ? "schedule-day--wedding-day" : ""}`}>
    <div className="date">{day}</div>
    <div className="schedule-day--subtitle">{label}</div>
    <div className="events">
      {events.map(({ title, time, description }) => (
        <div className="event">
          <div className="title-and-date">
            <div className="title">{title}</div>
            <div className="event-date">{time}</div>
          </div>
          <div className="description">{description}</div>
        </div>
      ))}
    </div>
  </div>
)

const Schedule = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const [openGiftDialog, setOpenGiftDialog] = useState(false)

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleOpenGiftDialog = () => {
    setOpenGiftDialog(true)
  }

  const handleCloseGiftDialog = () => {
    setOpenGiftDialog(false)
  }

  return (
    <Section wrapperClassNames="wrapper-grey" id="#schedule">
      <div className="schedule">
        <div className="schedule__header">
          Schedule
        </div>
        <div className="schedule__days">
          {daysList.map((day, index) => <Day {...day} first={index === 0} second={index === 1} />)}
        </div>
        <div className="schedule__dress-code">
          <SecondaryButton
            text="Check out the dress code"
            onClick={handleOpenDialog}
            className="schedule__dress-button"
          />
          <PrimaryButton
            text="Gift ideas"
            onClick={handleOpenGiftDialog}
            className="schedule__gifts-button"
          />
        </div>
      </div>
      <DressCodeDialog open={openDialog} onClose={handleCloseDialog} />
      <GiftsDialog open={openGiftDialog} onClose={handleCloseGiftDialog} />
    </Section>
  )
}


export default Schedule;
